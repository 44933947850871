
.glyph {
    width: 80vh;
    max-width: 80vw;
    height: 80vh;
    max-height: 80vw;
}

.glyph__outer,
.glyph__inner {
    transform-origin: 50px 71.1px;
}