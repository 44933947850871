.App {
  --blue: #61dafb;


  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  text-align: center;
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
